<template>
    <div class="zx-prod-bar">

        <div class="zx-prod-title" id="ht">
            <div class="zx-prod-box-1">
                <div class="zx-prod-box-1-left">
                    <h2>慧通</h2>
                    <div>数据安全流通平台</div>
                    <p>基于安全多方计算SMPC、联邦学习FL、同态加密HE、差分隐私DP等密码学、大数据、AI技术，在保障原始个人隐私和商业机密数据不出安全域的前提下，可控可度量地完成多方联合计算、联合分析、联合学习等多方协同任务。</p>
                </div>
                <div class="zx-prod-box-1-right">
                    <img src="../../assets/product/prod-title-right-icon-1.jpeg" alt="">
                </div>
            </div>
        </div>
        <div class="zx-prod-cont-bar">
            <div class="zx-prod-cont-title">
                <h2>核心功能</h2>
            </div>
            <div class="zx-prod-cont-box">
                <div class="zx-prod-cont-list" style="width: 100%">
                    <img src="../../assets/product/zx-advantage-list-icon-ht-1.png" alt="">
                    <h3>安全查询</h3>
                    <ul class="zx-prod-ht-gong">
                        <li>隐私信息查询（Private Information Retrieval ，PIR，匿踪查询），查询方仅仅知晓匹配的查询结果并且不留查询痕迹</li>
                        <li>隐私集合判定（Private Set Membership，PSM）判断一个元素是否属于一个集合，同时又不会暴露这个元素</li>
                        <li>隐私数据求交集（Private Set Intersection，PSI）可以得到正确的交集，而且不会得到交集以外另一方集合中的任何信息</li>
                    </ul>
                </div>
                <div class="zx-prod-cont-list" style="background: #2D56B6; ">
                    <img src="../../assets/product/zx-advantage-list-icon-ht-2.png" alt="">
                    <h3>联邦分析</h3>
                    <p>支持相关性分析、密文统计、联合分析，可对接大数据平台，实现密文数据分析</p>
                </div>
                <div class="zx-prod-cont-list" style="background: #294DA1;">
                    <img src="../../assets/product/zx-advantage-list-icon-ht-3.png" alt="">
                    <h3>联邦学习</h3>
                    <p>各参与方无需共享或交换各自敏感数据的情况下进行联合建模，支持横向联邦学习、纵向联邦学习常见模式。内置 LR、GBDT、DNN 等机器学习算法</p>
                </div>
            </div>
        </div>
        <div class="zx-prod-cont-bar" style="height: 770px;box-sizing: border-box">
            <div class="zx-prod-cont-title">
                <h2>应用场景</h2>
            </div>
            <div class="zx-prod-sub-nav">
                <div class="zx-prod-sub-nav-list" :class="subType == 'tab1'?'cur': ''" @click="subType = 'tab1'">
                    行业领域
                </div>
                <div class="zx-prod-sub-nav-list" :class="subType == 'tab2'?'cur': ''" @click="subType = 'tab2'">
                    业务场景
                </div>
            </div>
            <div style="height: 360px; position: relative;width: 1200px">
                <transition-group name="slide-fade">
                    <div class="zx-prod-scene-box" key="tab1" v-if="subType == 'tab1'">
                        <div class="zx-prod-scene-list">
                            <div class="zx-prod-scene-list-content">
                                <h2>政务</h2>
                                <p>政府机构可通过联邦计算搭建统一规范、互联互通、安全可控的政务数据开放服务，促进数据交易市场培育，安全合规推动政务数据开放与共享</p>
                            </div>
                        </div>
                        <div class="zx-prod-scene-list">
                            <div class="zx-prod-scene-list-content">
                                <h2>金融</h2>
                                <p>各方原始数据不出域的基础上打造金融数据共享基础设施，拓宽金融机构信贷风控的数据获取维度，构建一套包括黑名单共享在内的全流程联合风控体系</p>
                            </div>
                        </div>
                        <div class="zx-prod-scene-list">
                            <div class="zx-prod-scene-list-content">
                                <h2>物流</h2>
                                <p> 采用分布式本地部署架构，保障物流隐私数据去中心化，支持多个物流组织横向或纵向联邦学习提升物流模型效果，并通过匿踪查询对物流司机等信息隐私保护</p>
                            </div>
                        </div>
                    </div>
                    <div class="zx-prod-scene-box scent-2" key="tab2" v-else>
                        <div class="zx-prod-scene-list">
                            <div class="zx-prod-scene-list-content">
                                <h2>实名认证（要素认证）</h2>
                                <p>集成隐私计算平台，利用联合查询（PIR/PSI），隐藏认证要素，保护认证要素、业务访问模式和业务模型</p>
                            </div>
                        </div>
                        <div class="zx-prod-scene-list">
                            <div class="zx-prod-scene-list-content">
                                <h2>区域银行账号风险联防联控</h2>
                                <p>基于慧通隐私计算平台安全求交功能构建实时、安全的风险账户共享应用</p>
                            </div>
                        </div>
                        <div class="zx-prod-scene-list">
                            <div class="zx-prod-scene-list-content">
                                <h2>联合建模（风险评级）</h2>
                                <p>通过对接运营商上网及通信特征数据，丰富特征维度，提升风控模型泛化能力</p>
                            </div>
                        </div>
                    </div>
                </transition-group>
            </div>
        </div>
        <div class="zx-prod-cont-bar"  style="height: 900px;">
            <div class="zx-prod-cont-title">
                <h2>平台优势</h2>
            </div>
            <div class="zx-advantage-bar">
                <div class="zx-advantage-box">
                    <div class="zx-advantage-list" style="width: 387px">
                        <img src="../../assets/product/zx-adv-list-icon-ht-1.png" alt="">
                        <h3>领先的安全架构</h3>
                        <p>“分布式计算，集中式管控”架构；覆盖网络隔离、应用分组、密码协议、数据授权等多层面的安全实现和控制；支持按需应用安全水位，包括计算模型、安全模型等</p>
                    </div>
                    <div class="zx-advantage-list" style="width: 387px">
                        <img src="../../assets/product/zx-adv-list-icon-ht-2.png" alt="">
                        <h3>灵活的应用支持</h3>
                        <p>平台可实现完整的数据分析、特征加工，全流程可视化搭建。适合建模工程师的友好交互平台，提高建模效率。积累了丰富的行业应用场景基础模型</p>
                    </div>
                    <div class="zx-advantage-list" style="width: 387px">
                        <img src="../../assets/product/zx-adv-list-icon-ht-3.png" alt="">
                        <h3>工业级的工程能力</h3>
                        <p>工具链齐全，支持快速实施部署和平台迭代；采用集群分布式、并发计算、算法优化等策略，支持规模级大数据计算；支撑横向扩展，保障协同计算稳定高效</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "index.vue",
        data(){
            return {
                subType: 'tab1'
            }
        }
    }
</script>

<style scoped lang="scss">
    .zx-prod-title {
        width: 100%;
        background: #F6F8FA;
        height: 560px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .zx-prod-box-1 {
        display: flex;
        width: 1200px;
        justify-content: space-between;
        text-align: left;
        .zx-prod-box-1-left{
            margin-top: 140px;
        }
        h2{
            font-family: 'PingFang HK';
            font-style: normal;
            font-weight: normal;
            font-size: 48px;
            color: #121212;
            margin-bottom: 24px;
        }
        div{
            font-family: 'PingFang HK';
            font-style: normal;
            font-weight: 400;
            font-size: 34px;
            color: #305DC4;
            margin-bottom: 34px;
        }
        p{
            width: 490px;
            height: 105px;
            font-style: normal;
            font-weight: 300;
            font-size: 20px;
            line-height: 176.02%;
            text-align: justify;
            color: #121212;
        }
    }

    .zx-prod-cont-bar{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 140px;
        .zx-prod-cont-title{
            h2{
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: normal;
                font-size: 40px;
                line-height: 56px;
                color: #121212;
                position: relative;
                margin-bottom: 118px;
                &:after{
                    content: '';
                    position: absolute;
                    width: 70px;
                    height: 6px;
                    background: #305DC4;
                    border-radius: 11px;
                    left: 50%;
                    margin-left: -35px;
                    top: 90px;
                }
            }
        }
    }
    .zx-prod-cont-box{
        width: 1200px;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        .zx-prod-cont-list{
            width: 600px;
            height: 266px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            background-color: #305DC4;
            color: #fff;
            padding-left: 30px;
            box-sizing: border-box;
            img{
                padding-top: 53px;
                margin-bottom: 25px;
            }
            h3{
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                line-height: 176.02%;
                color: #FFFFFF;
                margin-bottom: 5px;
            }
            p{
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 176.02%;
                letter-spacing: 0.09em;
                color: rgba(255, 255, 255, 0.75);
                text-align: left;
            }
            &:nth-child(2){
                background-color: #2D56B6;
                img{
                    padding-top: 53px;
                }
            }
            &:nth-child(3){
                background-color: #2D56B6;
                img{
                    padding-top: 53px;
                }
            }
            &:nth-child(4){
                background-color: #294DA1;
                img{
                    padding-top: 53px;
                }
            }
            &:hover{
                box-shadow: 0px 5px 21px rgba(255, 255, 255, 0.1);
                transition: all .5s;
            }
        }
        .zx-prod-ht-gong{
            display: flex;
            width: 100%;
            justify-content: space-between;
            li{
                width: 340px;
                text-align: left;
                position: relative;
                &:nth-child(2):after,&:nth-child(1):after{
                    content: '';
                    width: 1px;
                    height: 63px;
                    background: rgba(255, 255, 255, 0.7);
                    border-radius: 8px;
                    position: absolute;
                    right: -24px;
                    top: 0;
                }
            }
        }
    }

    .zx-prod-cont-hz-box{
        width: 1200px;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        .zx-prod-cont-hz-list{
            width: 600px;
            height: 346px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            background-color: #305DC4;
            color: #fff;
            padding-left: 30px;
            box-sizing: border-box;
            width: 400px;
            &:nth-child(4){
                width: 800px;
            }
            img{
                padding-top: 53px;
                margin-bottom: 25px;
            }
            h3{
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                line-height: 176.02%;
                color: #FFFFFF;
                margin-bottom: 5px;
            }
            p{
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 176.02%;
                letter-spacing: 0.09em;
                color: rgba(255, 255, 255, 0.75);
                width: 329px;
                text-align: left;
            }
            &:nth-child(1){
                background: #305DC4;
                img{
                    padding-top: 58px;
                }
            }
            &:nth-child(2){
                background: #2D56B6;
                img{
                    padding-top: 58px;
                }
            }
            &:nth-child(3){
                background: #294DA1;
                img{
                    padding-top: 58px;
                }
            }
            &:nth-child(4){
                background: #2A54B6;
                img{
                    padding-top: 58px;
                }
            }
            &:nth-child(5){
                background: #305DC4;
                img{
                    padding-top: 58px;
                }
            }
            &:hover{
                box-shadow: 0px 5px 21px rgba(255, 255, 255, 0.1);
                transition: all .5s;
            }
        }
        .zx-prod-hz-gong{
            display: flex;
            width: 100%;
            justify-content: space-between;
            li{
                text-align: left;
                position: relative;
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 176.02%;
                /* or 25px */
                letter-spacing: 0.09em;
                color: rgba(255, 255, 255, 0.75);
                h4{
                    position: relative;
                    font-family: 'PingFang HK';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 176.02%;
                    color: #FFFFFF;
                    margin-bottom: 4px;
                    padding-left: 10px;
                    &::after{
                        content: '';
                        position: absolute;left: 0;top: 8px;
                        width: 3px;
                        height: 15px;
                        background: #FFFFFF;
                        border-radius: 16px;
                    }
                }
                &:nth-child(1){
                    width: 470px;
                    span{
                        display: block;
                        width: 472px;
                    }
                }
                &:nth-child(2){
                    width: 250px;
                    span{
                        display: block;
                        width: 207px;
                    }
                }
                &:nth-child(1):after{
                    content: '';
                    width: 1px;
                    height: 145px;
                    background: rgba(255, 255, 255, 0.7);
                    border-radius: 8px;
                    position: absolute;
                    right: -24px;
                    top: 0;
                }
            }
        }
    }
    .zx-prod-scene-box{
        width: 1200px;
        position: absolute;
        left: 0;
        display: flex;
        justify-content: space-between;
        .zx-prod-scene-list{
            width: 390px;
            height: 306px;
            position: relative;
            overflow: hidden;
            cursor: pointer;
            &:nth-child(1){
                background: url("../../assets/product/zx-ht-csent-1.png") no-repeat 100%;
            }
            &:nth-child(2){
                background: url("../../assets/product/zx-ht-csent-2.png") no-repeat 100%;
            }
            &:nth-child(3){
                background: url("../../assets/product/zx-ht-csent-3.png") no-repeat 100%;
            }
            .zx-prod-scene-list-content{
                position: absolute;
                left: 0;
                top: 240px;
                background: rgba(18, 18, 18, 0.55);
                height: 306px;
                transition: all .5s;
            }
            h2{
                height: 66px;
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                line-height: 66px;
                color: #FFFFFF;
                margin-bottom: 5px;
            }
            p{
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 1.76;
                color: #fff;
                text-align: left;
                padding: 0 20px;
            }
            &:hover{
                .zx-prod-scene-list-content{
                    top: 0;
                    transition: all .5s;
                }
            }
        }
        &.scent-2{
            .zx-prod-scene-list{
                &:nth-child(1){
                    background: url("../../assets/product/zx-scene-bg-1.png") no-repeat 100%;
                }
                &:nth-child(2){
                    background: url("../../assets/product/zx-scene-bg-2.png") no-repeat 100%;
                }
                &:nth-child(3){
                    background: url("../../assets/product/zx-scene-bg-3.png") no-repeat 100%;
                }
            }
        }
    }

    .zx-advantage-bar{
        display: flex;
        align-items: center;
    }
    .zx-advantage-box{
        display: flex;
        justify-content: space-between;
        width: 1200px;
        .zx-advantage-list{
            width: 285px;
            height: 420px;
            background: #FFFFFF;
            border: 1px solid rgba(153, 156, 176, 0.12);
            box-shadow: 0px 4px 24px rgba(153, 156, 176, 0.32);
            border-radius: 4px;
            padding-top: 74px;
            display: flex;
            flex-direction: column;
            align-items: center;
            img{
                margin-bottom: 40px;
            }
            h3{
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                line-height: 176.02%;
                color: #121212;
                margin-bottom: 62px;
            }
            p{
                text-align: left;
                padding: 0 20px;
            }
        }
    }
    .zx-prod-sub-nav{
        display: flex;
        justify-content: space-around;
        width: 755px;
        margin-bottom: 90px;
        .zx-prod-sub-nav-list{
            width: 230px;
            text-align: center;
            height: 60px;
            font-size: 34px;
            cursor: pointer;
            position: relative;
            &.cur:after{
                content: '';
                position: absolute;
                width: 230px;
                height: 3px;
                background: #2653B8;
                border-radius: 4px;
                left: 0;
                bottom: -3px;
                transition: all .5s;
            }
        }
    }
</style>
